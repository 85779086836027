import React, { useEffect, useState, useRef } from "react";
import styles from "./flavor-profile-card2.module.scss";
import * as constants from "../../../constants/constants";
import useSWR from "swr";
import { get } from "../../../lib/fetch";
import { isNumber } from "../../../lib/common";

export default function FlavorProfileCard2({
  varietal = undefined,
  noSubtitle,
}) {
  const [profile, setProfile] = useState(undefined);
  const [flavors, setFlavors] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `https://winery-service.pairanything.com/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (varietalProfiles && varietal) {
      setProfile(varietalProfiles[varietal.trimEnd()]);
    }
  }, [varietalProfiles, varietal]);

  useEffect(() => {
    if (!!profile) {
      let arr = [];
      Object.keys(profile?.flavors).map((flavor, i) => {
        arr.push({
          name: flavor,
          src:
            [flavor] in profile?.flavors
              ? profile?.flavors[flavor][
                  Math.floor(Math.random() * profile?.flavors[flavor].length)
                ]
              : undefined,
        });
      });
      setFlavors(arr);
    }
  }, [profile]);

  console.log(`profile:`);
  console.log(profile);

  if (!profile) return <></>;

  return (
    <div className={`${styles.varietalCard}`}>
      {!noSubtitle && (
        <div className={`${styles.profileNotes}`}>
          <p className={`p4-x-bold`}>Flavor Profile</p>
          <p className={`p1-reg`}>{profile?.pairing_notes}</p>
        </div>
      )}

      <div className={`${noSubtitle ? styles.flavorList2 : styles.flavorList}`}>
        {flavors?.map((flavor, i) => {
          if (i >= 3) return <></>;
          return (
            <div className={`${styles.flavorListItem}`} key={i}>
              <img src={flavor.src} className={`${styles.flavorListImg}`} />
              <p className={`p5-med`}>{flavor.name}</p>
            </div>
          );
        })}
      </div>
      <div className={`${styles.ratingsList}`}>
        {isNumber(profile?.sweetness) && (
          <MeasureBar
            value={profile?.sweetness}
            maxValue={5}
            minName="Dry"
            maxName="Sweet"
          />
        )}
        {isNumber(profile?.body) && (
          <MeasureBar
            value={profile?.body}
            maxValue={5}
            minName="Light"
            maxName="Bold"
          />
        )}
        {isNumber(profile?.tannins) && (
          <MeasureBar
            value={profile?.tannins}
            maxValue={5}
            minName="Low Tannin"
            maxName="High Tannin"
          />
        )}
        {isNumber(profile?.acidity) && (
          <MeasureBar
            value={profile?.acidity}
            maxValue={5}
            minName="Low Acidity"
            maxName="High Acidity"
          />
        )}
      </div>
    </div>
  );
}

const MeasureBar = ({ value, maxValue, minName, maxName }) => {
  value = value < 0 ? 0 : value > 5 ? 5 : value;

  return (
    <div className={`${styles.ratingsListItem}`}>
      <p className={`${styles.valueLeft} ${value < 3 ? `p7-bold` : `p7-reg`} `}>
        {minName}
      </p>
      <div className={`${styles.measureBar}`}>
        {[...new Array(5)].map((_, idx) => {
          if (idx + 1 == value)
            return <div key={idx} className={`${styles.valuedBar}`} />;
          return <div key={idx} className={`${styles.notValuedBar}`} />;
        })}
      </div>
      <p
        className={`${styles.valueRight} ${value >= 4 ? `p7-bold` : `p7-reg`}`}
      >
        {maxName}
      </p>
    </div>
  );
};
