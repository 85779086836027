import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  initializeGA,
  logPagesVisitedByUser,
} from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import "react-phone-number-input/style.css";
import { formatCurrency } from "../../lib/common/convertCurrency";
import { useQuery } from "../../lib/hooks/useQueryParams";
import styles from "./food-card.module.scss";
import ActionsRow from "../../components/TargetComponents/ActionsRow/ActionsRow";
import PairingFeedback from "../../components/TargetComponents/PairingFeedback/PairingFeedback";
import Feedback from "../../components/TargetComponents/PairingFeedback/Feedback";

export default function FoodCardWines({
  pairingResult,
  varietalDescription,
  data,
}) {
  const { foodPairing } = pairingResult;

  console.log(`data:`);
  console.log(data?.reco);

  return (
    <div className={styles.mainContainer}>
      <div className={`mc`}>
        <img
          className={`${styles.foodImg}`}
          src={
            !!pairingResult?.image
              ? pairingResult.image
              : "https://www.myfoodom.com/wp-content/uploads/2022/02/veggie-burger-.jpg"
          }
          alt="food"
        />
      </div>
      <div className={`mc ${styles.headerInfo}`}>
        <h1 className={`h1-30 ${styles.foodNameText}`}>
          {foodPairing?.foodName}
        </h1>
        <p className={`${styles.descriptionText}`}>{data?.reco?.wineDescription}</p>
      </div>
      {data?.reco?.id < 999 && (
        <div className={`mc`}>
          <button
            className={`${styles.buyBtn}`}
            onClick={() => {
              window.open(
                `https://target.com/s?searchTerm=${foodPairing?.foodName}`,
                "_blank"
              );
            }}
          >
            <p className={`pb-lg`}>Buy at Target</p>
            <svg
              className={`no-shrink`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
            >
              <path
                d="M10.6663 7.11161V10.7781C10.6663 11.1023 10.5376 11.4131 10.3084 11.6423C10.0792 11.8715 9.76829 12.0003 9.44415 12.0003H2.72218C2.39804 12.0003 2.08717 11.8715 1.85797 11.6423C1.62876 11.4131 1.5 11.1023 1.5 10.7781V4.05616C1.5 3.73202 1.62876 3.42115 1.85797 3.19195C2.08717 2.96275 2.39804 2.83398 2.72218 2.83398H6.38871"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.83325 1H12.4998V4.66653"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.7771 7.72198L12.4991 1"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}

      <ActionsRow
        key={pairingResult?.info?.id}
        itemType="wine_reco"
        pairingResult={pairingResult}
      />

      <div className={`mc`} style={{ marginTop: "24px" }}>
        <Feedback />
      </div>
    </div>
  );
}
