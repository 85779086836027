import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import ChevronRight from "../../../assets/images/PAIcons/chevron-right.svg";
import TargetLogo from "../../../assets/images/PALogos/target-logo.png";
import "react-phone-number-input/style.css";
import styles from "./varietal-card.module.scss";
import ActionsRow from "../../components/TargetComponents/ActionsRow/ActionsRow";
import PairingFeedback from "../../components/TargetComponents/PairingFeedback/PairingFeedback";
import FlavorProfileCard from "../../components/TargetComponents/FlavorProfileCard/FlavorProfileCard";
import WineCard from "../../components/TargetComponents/WineCard/WineCard";
import WineTypePill from "../../components/TargetComponents/WineTypePill/WineTypePill";
import DefaultFoodImage from "../../../assets/images/PAImages/default-food-SHW-PA-2.webp";
import { get } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import Feedback from "../../components/TargetComponents/PairingFeedback/Feedback";

export default function VarietalCard({
  searchValue,
  foodName,
  varietalName,
  pairingResult,
}) {
  const history = useHistory();

  const [profile, setProfile] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (varietalProfiles && varietalName) {
      setProfile(varietalProfiles[varietalName.trimEnd()]);
    }
  }, [varietalProfiles, varietalName]);

  const [wines, setWines] = useState(undefined);

  useEffect(() => {
    if (pairingResult?.wines.length > 0) {
      setWines(
        pairingResult.wines.filter((wine) => {
          return wine.wineryId == 405;
        })
      );
    } else {
      setWines([]);
    }
  }, [varietalName, pairingResult]);

  if (!profile || !pairingResult) {
    return <></>;
  }

  return (
    <div className={`w100`}>
      <div className={`mc ${styles.pairingImgBox} flex-row`}>
        <div className={`${styles.pairingImg}`}>
          <img className={styles.foodImg} src={DefaultFoodImage} alt="food" />
          <img
            className={styles.varietalImg}
            src={profile.wine_url}
            alt="varietal bottle"
          />
        </div>
      </div>
      <div className={styles.mainContainerSection}>
        <div className={`mc`}>
          <div className={`${styles.info}`}>
            {pairingResult && (
              <WineTypePill type={pairingResult?.varietal?.wineTypeName} />
            )}
            <h1 className={`h1-30`}>{varietalName}</h1>
            <p className={`p1-reg`}>{profile.pairing_notes}</p>
          </div>
        </div>
        {/* <div className={`mc`}>
          <button
            className={`${styles.buyBtn}`}
            onClick={() => {
              window.open(
                `https://target.com/s?searchTerm=${varietalName}`,
                "_blank"
              );
            }}
          >
            <p className={`pb-lg`}>Buy Now</p>
            <svg
              className={`no-shrink`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
            >
              <path
                d="M10.6663 7.11161V10.7781C10.6663 11.1023 10.5376 11.4131 10.3084 11.6423C10.0792 11.8715 9.76829 12.0003 9.44415 12.0003H2.72218C2.39804 12.0003 2.08717 11.8715 1.85797 11.6423C1.62876 11.4131 1.5 11.1023 1.5 10.7781V4.05616C1.5 3.73202 1.62876 3.42115 1.85797 3.19195C2.08717 2.96275 2.39804 2.83398 2.72218 2.83398H6.38871"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.83325 1H12.4998V4.66653"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.7771 7.72198L12.4991 1"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div> */}
        <div className={`w100`}>
          <ActionsRow
            pairingResult={pairingResult}
            key={pairingResult?.varietal?.id}
            foodName={foodName}
            itemType="food_reco"
          />
        </div>
        <div className={`mc`}>
          <FlavorProfileCard varietal={varietalName} />
        </div>
        {!!wines && wines.length > 0 ? (
          <div className={`mc`}>
            <Wines
              key={varietalName}
              wines={wines}
              varietalName={varietalName}
              searchValue={searchValue}
              goBack={pairingResult?.varietal?.varietalRecoId}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={`mc`} style={{marginTop: "24px"}}>
        <Feedback />
      </div>
    </div>
  );
}

const Wines = ({ wines, varietalName, searchValue, goBack }) => {
  // console.log(`goBack:`);
  // console.log(goBack);
  const history = useHistory();

  const clickSeeAll = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/see-all-wines/${varietalName}`,
      state: { food: `${searchValue}`, goBack: goBack },
    });
  };

  return (
    <div className={styles.getWinesSection}>
      <div
        className="flex-row"
        style={{
          justifyContent: "space-between",
          alignItems: "baseline",
          width: "100%",
        }}
      >
        <div className={styles.getBox}>
          <div className={`${styles.getAtTarget}`}>Get it at Target</div>
          <img
            className={styles.targetLogo}
            src={TargetLogo}
            alt="target logo"
          />
        </div>
        <button className={styles.seeAllBtn} onClick={(e) => clickSeeAll(e)}>
          <p className={`p1-bold ${styles.seeAllBtnTxt}`}>See All</p>
          <img src={ChevronRight} alt="chevron right" />
        </button>
      </div>

      <div
        className="flex-row"
        style={{
          overflow: "hidden",
          width: "100%",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "22px",
        }}
      >
        {wines.slice(0, 2).map((wine, idx) => (
          <WineCard
            key={idx}
            wine={wine}
            food={searchValue}
            returnPage={history.location.pathname}
          />
        ))}
        {wines.length == 1 ? <WineCard ghost /> : <></>}
      </div>
    </div>
  );
};
