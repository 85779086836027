import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import "react-phone-number-input/style.css";
import styles from "./demo-wine-details.module.scss";
import FlavorProfileCard from "../../components/TargetComponents/FlavorProfileCard/FlavorProfileCard";
import WineTypePill from "../../components/TargetComponents/WineTypePill/WineTypePill";
import DefaultFoodImage from "../../../assets/images/PAImages/default-food-SHW-PA-2.webp";
import { get, postEvent } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import Layout from "../../components/CommonComponents/layout";
import AnimationWrapper from "../../components/TargetComponents/AnimationWrapper/AnimationWrapper";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import PAHeaderInline from "../../components/CommonComponents/header-inline";
import FlavorProfileCard2 from "../../components/TargetComponents/FlavorProfileCard/FlavorProfileCard2";
import ActionsRow from "../../components/TargetComponents/ActionsRow/ActionsRow";
import ChevronLeft from "../../../assets/images/PAIcons/chevron-left.svg";
import PlacerLogo from "../../../assets/images/RanchoRobleDemo/placer-logo.png";
import RobleLogo from "../../../assets/images/RanchoRobleDemo/roble-logo.png";
import Feedback from "../../components/TargetComponents/PairingFeedback/Feedback";
import FoodIcon from "../../../assets/images/PAIcons/SearchFoodIcon.svg";
import { useLocation } from "react-router-dom";

export default function DemoWineDetails() {
  const pairingData = ["Chicken Salad", "Fish Tacos", "Tuna Casserole"];
  const { wineryName } = useParams();
  const { wineId } = useParams();
  let { search } = useLocation();
  let searchParams = new URLSearchParams(search);
  let fromQR = searchParams.get("qr");

  useEffect(() => {
    const fn = async (body) => {
      try {
        await postEvent(body);
      } catch (e) {
        console.error(e);
      }
    };

    if (fromQR == "3") {
      fn({
        eventName: "page_landQR",
        eventDetails: fromQR,
        pageUrl: window.location.href,
      });
      history.replace(window.location.pathname);
    } else {
      fn({
        eventName: "page_land",
        pageUrl: window.location.href,
      });
    }
  }, []);

  const ref = useRef(null);

  // ====vvv

  const [wineUrl, setWineUrl] = useState(null);
  const [wineDetailsLoaded, setWineDetailsLoaded] = useState(false);

  useEffect(() => {
    setWineUrl(`${constants.WINERY_API}/api/v1/public/wine/${wineId}`);
  }, []);

  const [varietalName, setVarietalName] = useState(undefined);

  const { data: wineDetails } = useSWR(wineUrl, (u) =>
    get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (wineDetails) {
      if (wineDetails?.wineName == "Survivor Old Vine Zinfandel") {
        setVarietalName("Old Vine Zinfandel");
      } else if (wineDetails?.id == 1799) {
        setVarietalName("Rose Barb");
      } else if (wineDetails?.id == 1801) {
        setVarietalName("Rancho Barb");
      } else if (wineDetails?.id == 1798) {
        setVarietalName("Rancho Chard");
      } else {
        setVarietalName(wineDetails?.specifications?.varietal);
      }

      setWineDetailsLoaded(true);
    }
  }, [wineDetails]);
  // ====^^^

  const history = useHistory();

  const [profile, setProfile] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (varietalProfiles && varietalName) {
      setProfile(varietalProfiles[varietalName.trimEnd()]);
    }
  }, [varietalProfiles, varietalName]);

  const clickBack = (e) => {
    e.preventDefault();
    const url = window.localStorage.getItem("winery_url");
    url
      ? history.push({
          pathname: `/${JSON.parse(url)}`,
        })
      : history.push("/welcome");
  };

  const clickSeeMore = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const clickSeePairings = () => {
    if (wineDetails.id == "1390") {
      history.push("/pairing-results-wine/1000");
    } else if (wineDetails.id == "1391") {
      history.push("/pairing-results-wine/1001");
    } else if (wineDetails.id == "1392") {
      history.push("/pairing-results-wine/1002");
      // --------------------
    } else if (wineDetails.id == "1799") {
      history.push("/pairing-results-wine/1000");
    } else if (wineDetails.id == "1801") {
      history.push("/pairing-results-wine/1001");
    } else if (wineDetails.id == "1798") {
      history.push("/pairing-results-wine/1002");
    }
  };

  if (!profile || !wineDetailsLoaded) return <></>;

  return (
    <Layout white>
      <AnimationWrapper animation={"fromRight"}>
        <LayoutContents>
          <PAHeaderInline left />
          <button
            className={styles.backToResultBtn}
            onClick={(e) => clickBack(e)}
          >
            <img src={ChevronLeft} alt="chevron left" />
            <p className={`p1-reg ${styles.backToResultBtnTxt}`}>Back</p>
          </button>
          <div className={`w100`}>
            <div className={`mc ${styles.pairingImgBox} flex-row`}>
              <div className={`${styles.pairingImg}`}>
                <div className={`${styles.logosBox}`}>
                  <img
                    className={styles.placerLogo}
                    src={PlacerLogo}
                    alt={"Placer Wine Trail Logo"}
                  />
                  <img
                    className={styles.robleLogo}
                    src={RobleLogo}
                    alt={"Rancho Roble Vineyards Logo"}
                  />
                </div>
                <img
                  className={styles.varietalImg}
                  src={wineDetails?.wineImageSmall}
                  alt="wine bottle"
                />
              </div>
            </div>
            <div className={styles.mainContainerSection}>
              <div className={`mc`}>
                <div className={`${styles.info}`}>
                  <WineTypePill type={wineDetails?.wineType} />

                  <h1 className={`h1-30`}>{wineDetails?.wineName}</h1>
                  <div className={`${styles.fadeBox}`}>
                    <p className={`p1-reg ${styles.fade}`}>
                      {wineDetails?.wineDescription}
                    </p>
                    <p
                      onClick={() => clickSeeMore()}
                      className={`p1-reg ${styles.fadeSeeMore}`}
                    >
                      See More
                    </p>
                  </div>
                </div>
              </div>
              <div className={`mc`}>
                <button
                  className={`${styles.buyBtn}`}
                  onClick={() => {
                    window.open(wineDetails?.wineUrl, "_blank");
                  }}
                >
                  <p className={`pb-lg`}>Buy Now</p>
                  <svg
                    className={`no-shrink`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                  >
                    <path
                      d="M10.6663 7.11161V10.7781C10.6663 11.1023 10.5376 11.4131 10.3084 11.6423C10.0792 11.8715 9.76829 12.0003 9.44415 12.0003H2.72218C2.39804 12.0003 2.08717 11.8715 1.85797 11.6423C1.62876 11.4131 1.5 11.1023 1.5 10.7781V4.05616C1.5 3.73202 1.62876 3.42115 1.85797 3.19195C2.08717 2.96275 2.39804 2.83398 2.72218 2.83398H6.38871"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.83325 1H12.4998V4.66653"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.7771 7.72198L12.4991 1"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className={`w100`}>
                <ActionsRow itemType="wine" wineId={wineId} />
              </div>
              <div className={`mc`}>
                <div className={`${styles.varietalCard}`}>
                  <FlavorProfileCard2 varietal={varietalName} noSubtitle />
                </div>
                <div className={`${styles.flavorProfBox}`}>
                  <p className={`h3-16`} style={{ marginBottom: "8px" }}>
                    Flavor Profile
                  </p>
                  <p ref={ref} className={`p1-reg`}>
                    {wineDetails?.wineDescription}
                  </p>
                </div>
                <div className={`${styles.pairingsBox}`}>
                  <p className={`h3-16`} style={{ marginBottom: "8px" }}>
                    Pairings
                  </p>
                  <div className={`${styles.gradientContainer}`}>
                    <div className={`${styles.gradient}`}>
                      <button
                        className={`${styles.viewFull}`}
                        onClick={() => clickSeePairings()}
                      >
                        See Pairings
                      </button>
                    </div>
                    {pairingData.map((item) => (
                      <div key={item} className={`${styles.phoneticResults}`}>
                        <img
                          className={`${styles.phoneticResultsImg}`}
                          src={FoodIcon}
                          alt="Food Icon"
                        />
                        <div>{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={`mc`}>
              <Feedback />
            </div>
          </div>
        </LayoutContents>
      </AnimationWrapper>
    </Layout>
  );
}
