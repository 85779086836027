import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { logPagesVisitedByUser } from "../../components/PAGoogleAnalytics";
import { isMobile } from "react-device-detect";
import styles from "./winery-landing.module.scss";
import WineIcon from "../../../assets/images/PAIcons/SearchWineIcon.svg";
import FoodIcon from "../../../assets/images/PAIcons/SearchFoodIcon.svg";
import SearchIcon from "../../../assets/images/PAIcons/search.svg";
import PAHeader from "../../components/CommonComponents/header-inline";
import Layout from "../../components/CommonComponents/layout";
import "react-phone-number-input/style.css";
import { DebounceInput } from "react-debounce-input";
import LandingPageCard from "./spotlight-card";
import useSWR, { preload } from "swr";
import { GOOGLE_SEARCH_API } from "../../constants/constants";
import { useSessionUrl } from "../../lib/hooks/useSession";
import { useLoggedIn } from "../../lib/hooks/useLoggedIn";
import TargetLogo from "../../../assets/images/PALogos/targetlogo.png";
import * as constants from "../../constants/constants";
import moment from "moment";
import AnimationWrapper from "../../components/TargetComponents/AnimationWrapper/AnimationWrapper";
import LayoutContents from "../../components/CommonComponents/layout-contents";
import { put, post, get } from "../../lib/fetch";
import { sessionUrl } from "../../lib/common/sessionUrl";
import SavedWineCard from "../../components/TargetComponents/SavedWineCard/SavedWineCard";
import SavedPairingCard from "../../components/TargetComponents/SavedPairingCard/SavedPairingCard";

export default function LandingPage() {
  const { loggedIn, roleId, consumerData } = useLoggedIn();

  // ++++++++++ preload varietal profs +++++++

  preload(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  const history = useHistory();
  const { wineryName } = useParams();
  const from = history.location.state?.from;
  const currentPage = history.location.pathname;

  // ::::::::: Get Winery Details from url params :::::::::::

  let wineryDetailsUrl = `${constants.WINERY_API}/api/v1/public/winery/get/wineryByUniqueUrl/${wineryName}`;

  const { data: wineryDetails } = useSWR(wineryDetailsUrl, (u) =>
    get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (!!wineryDetails) {
      localStorage.setItem("winery_id", JSON.stringify(wineryDetails.id));
      localStorage.setItem(
        "winery_url",
        JSON.stringify(wineryDetails.wineryUniqueUrl)
      );
    }
  }, [wineryDetails]);

  console.log(`wineryDetails:`);

  if (currentPage.toLowerCase() == "/targetcalifornia") {
    ReactGA.event({
      category: "TargetCalifornia",
      action: "qr_code",
    });
  }

  // ########################## loading spotlight wines ############################

  const [spotlightLoaded, setSpotlightLoaded] = useState(false);

  const { data: spotlights, error: spotlightsError } = useSWR(
    () => {
      return `${
        constants.WINERY_API
      }/api/v1/public/spotlightDeal/getActiveSpotlightsBasedOnWinery?wineryId=${
        wineryDetails.id
      }&dateSearch=${moment().utc().format("YYYY-MM-DD")}`;
    },
    (u) => get(u).then((res) => res.json())
  );

  const [spotlightWines, setSpotlightWines] = useState(undefined);

  useEffect(() => {
    if (!!spotlightLoaded) {
      setSpotlightWines(spotlights);
    }
  }, [spotlightLoaded]);

  useEffect(() => {
    if (!!spotlights) {
      if (!spotlights.errors) {
        setSpotlightLoaded(true);
      } else {
        console.error(spotlights);
      }
    }
    if (!!spotlightsError) {
      // needs implementation ?
      console.error(spotlightsError);
    }
  }, [spotlights, spotlightsError]);

  // >>>>>>>>>> API for search >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // True when search bar is clicked
  const [searchFocus, setSearchFocus] = useState(!!from);

  useEffect(() => {
    logPagesVisitedByUser();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [resultUrl, setResultUrl] = useState(null);
  const [phonicsActive, setPhonicsActive] = useState(false);

  const { data: searchResultPhonic } = useSWR(resultUrl, (u) =>
    get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (searchValue.length >= 2) {
      setPhonicsActive(true);
      setResultUrl(
        `${GOOGLE_SEARCH_API}/api/v1/public/foodNamesDetails/getPhonetic?value=${searchValue}&isCustomSearch=true`
      );
    } else {
      //cache.delete(resultUrl);
      setPhonicsActive(false);
      setResultUrl(null);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchFocus) {
      setTimeout(() => {
        //window.scrollTo(0, 0);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }, 200);
    }
  }, [searchFocus]);

  // oooooooooooo USER SAVES oooooooooooooooooooooo

  const [userSavedPairings, setUserSavedPairings] = useState(undefined);

  const { data: userSaves } = useSessionUrl(
    `${constants.IDENTITY_API}/api/v1/public/user/save/getUserSavesSaturated`
  );

  // filter out saved wines
  useEffect(() => {
    if (!!userSaves && userSaves.length > 0) {
      setUserSavedPairings(
        userSaves.filter((save) => {
          return save.item.itemType != 4;
        })
      );
    }
  }, [userSaves]);

  console.log(`userSaves:`);
  console.log(userSaves);

  // oooooooooooo USER HISTORY oooooooooooooooooooooo

  const [userSearchHistory, setUserSearchHistory] = useState(undefined);

  const { data: searchHistory } = useSessionUrl(
    `${constants.IDENTITY_API}/api/v1/public/user/like/searchHistory`
  );

  useEffect(() => {
    if (!!searchHistory && searchHistory.length > 0) {
      setUserSearchHistory(searchHistory.slice(0, 3));
    }
  }, [searchHistory]);

  console.log(`userSearchHistory:`);
  console.log(userSearchHistory);

  // oooooooooo onClick Functions oooooooooooooooooooooooooooooo

  const onClickPhonetic = async (result) => {
    const recoBody = {
      wineryId: localStorage.getItem("winery_id"),
    };
    const phonetic = result?.name;
    const filtered = phonetic?.split("/")[0].trimEnd();

    let url = `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/food/bySearch/${filtered}`;

    try {
      if (!result?.isFood) {
        const { varietalId } = result;
        let url = `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/varietal/byId/${varietalId}`;

        const response = await post(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          recoBody
        );
        const { recoId } = await response.json();

        console.log(recoId);

        history.push({
          pathname: `/pairing-results-food/${recoId}`,
          state: { from: "fromLanding" },
        });
      } else {
        const response = await post(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          recoBody
        );
        const result = await response.json();

        console.log(`result:`);
        console.log(result);

        history.push({
          pathname: `/pairing-results/${result?.recoId}`,
          state: { from: "fromLanding" },
        });
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const onHitEnter = async (result) => {
    const recoBody = {
      wineryId: localStorage.getItem("winery_id"),
    };
    const phonetic = result?.name;
    var filtered = phonetic?.split("/")[0].trimEnd();
    var filteredSearchValue = searchValue?.split("/")[0].trimEnd();

    let url = `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/food/bySearch/${filteredSearchValue}`;

    try {
      if (!result?.isFood) {
        //this usually won't happen
        history.push({
          pathname: `/pairing-results-food/${filtered}`,
          state: { from: "fromLanding" },
        });
      } else {
        const response = await post(
          sessionUrl(url),
          {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          recoBody
        );
        var result1 = await response.json();

        if (result1?.searchHit) {
          history.push({
            pathname: `/pairing-results/${result1?.recoId}`,
            state: { from: "fromLanding" },
          });
        } else {
          let url = `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/food/bySearch/${filtered}`;

          const response = await post(
            sessionUrl(url),
            {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            recoBody
          );
          const result2 = await response.json();

          if (result2?.searchHit) {
            history.push({
              pathname: `/pairing-results/${result2?.recoId}`,
              state: {
                from: "fromLanding",
                searchMiss: {
                  id: result1.recoId,
                  value: filteredSearchValue,
                },
              },
            });
          }
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const onHitEnterNoResults = async () => {
    const recoBody = {
      wineryId: localStorage.getItem("winery_id"),
    };
    var filteredSearchValue = searchValue?.split("/")[0].trimEnd();

    let url = `${constants.GOOGLE_SEARCH_API}/api/v1/public/searchReco/food/bySearch/${filteredSearchValue}`;

    try {
      const response = await post(
        sessionUrl(url),
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        recoBody
      );
      const result = await response.json();

      if (result.searchHit) {
        history.push({
          pathname: `/pairing-results/${result?.recoId}`,
          state: {
            from: "fromLanding",
          },
        });
      } else {
        history.push({
          pathname: `/pairing-results/${result?.recoId}`,
          state: {
            from: "fromLanding",
            noResult: true,
            searchMiss: {
              id: result.recoId,
              value: filteredSearchValue,
            },
          },
        });
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const onClickHistory = async (item) => {
    if (item.itemType == 1) {
      history.push({
        pathname: `/pairing-results/${item.recoId}`,
        state: {
          from: "fromLanding",
        },
      });
    } else if (item.itemType == 2) {
      history.push({
        pathname: `/pairing-results-food/${item.recoId}`,
        state: {
          from: "fromLanding",
        },
      });
    }
  };

  return (
    <Layout white>
      <AnimationWrapper animation={!from ? "fromRight" : "none"}>
        <LayoutContents>
          <AnimationWrapper animation={"collapse"} active={searchFocus} gray>
            <PAHeader left />
            <div className={`${styles.pageSection}`}>
              <div className={styles.titleBox}>
                <img
                  className={`${styles.partnerLogo}`}
                  src={wineryDetails?.logo || null}
                  alt="partner logo"
                />
                <div className={styles.welcomeBox}>
                  {loggedIn && roleId == 3 ? (
                    <>
                      <h1 className={`h1-30`}>
                        {`Welcome to ${wineryDetails?.displayName}, ${consumerData?.firstName}`}
                      </h1>
                      <p className={`p1-reg`}>
                        Set up and review today's wines to get started.
                      </p>
                    </>
                  ) : loggedIn && roleId != 3 ? (
                    <>
                      <h1 className={`h1-30`}>
                        {`Welcome to ${wineryDetails?.displayName}, ${consumerData?.firstName}`}
                      </h1>
                      <p className={`p1-reg`}>Start pairing below.</p>
                    </>
                  ) : (
                    <>
                      <h1
                        className={`h1-30`}
                      >{`Welcome to ${wineryDetails?.displayName}!`}</h1>
                      <p className={`p1-reg`}>
                        Start pairing below, or{" "}
                        <span
                          className={styles.loginSignupText}
                          onClick={() =>
                            history.push({
                              pathname: `/login`,
                            })
                          }
                        >
                          Log in / sign up.
                        </span>
                      </p>
                    </>
                  )}
                </div>
                {loggedIn && roleId == 3 ? (
                  <button className={`${styles.setupBtn}`}>Set Up Wines</button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </AnimationWrapper>

          <div className={`${styles.pageSection2}`}>
            <div className={styles.searchContainer}>
              {" "}
              <AnimationWrapper animation={"expand"} active={searchFocus}>
                <button
                  className={`${styles.closeBtn}`}
                  onClick={() => {
                    setSearchFocus(false);
                    setSearchValue("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M11 1L1 11"
                      stroke="#7D7D7D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L11 11"
                      stroke="#7D7D7D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </AnimationWrapper>
              <div className={`${styles.searchBox}`}>
                <h2 className={`h2-22 ${styles.whatPairsWith}`}>
                  What pairs with...
                </h2>
                <div className={styles.inputWithIcon}>
                  <img src={SearchIcon} alt="search icon" />
                  <DebounceInput
                    autoFocus={!!from}
                    className={`p1-reg ${styles.noShrink}`}
                    debounceTimeout={500}
                    placeholder={`Try "tacos" or "moscato"`}
                    type="text"
                    name="dish-name"
                    value={searchValue}
                    onFocus={() => setSearchFocus(true)}
                    onChange={(event) => setSearchValue(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        if (
                          searchResultPhonic &&
                          searchResultPhonic.length > 0
                        ) {
                          onHitEnter(searchResultPhonic[0]);
                        } else if (
                          searchResultPhonic &&
                          searchResultPhonic.length == 0
                        ) {
                          onHitEnterNoResults();
                        }
                      }
                    }}
                  />
                  <div className={styles.pairBtnHidden}>
                    <AnimationWrapper animation="fadeIn" active={phonicsActive}>
                      <button
                        className={`${styles.pairBtn} ${
                          !phonicsActive ? styles.btnNoClick : null
                        }`}
                        onClick={() => {
                          if (
                            searchResultPhonic &&
                            searchResultPhonic.length > 0
                          ) {
                            onHitEnter(searchResultPhonic[0]);
                          } else if (
                            searchResultPhonic &&
                            searchResultPhonic.length == 0
                          ) {
                            onHitEnterNoResults();
                          }
                        }}
                      >
                        Pair
                      </button>
                    </AnimationWrapper>
                  </div>
                </div>
              </div>
              {!!searchResultPhonic ? (
                searchResultPhonic.map((result, idx) => (
                  <div
                    key={idx}
                    className={`${styles.phoneticResults}`}
                    onClick={() => onClickPhonetic(result)}
                  >
                    <img
                      className={`${styles.phoneticResultsImg}`}
                      src={result?.isFood ? FoodIcon : WineIcon}
                      alt="Wine Icon"
                    />
                    <div>{result.name}</div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>

            {!phonicsActive ? (
              <>
                {spotlightLoaded &&
                !!spotlightWines &&
                spotlightWines.length != 0 &&
                wineryDetails?.id == "211" ? (
                  <div className={styles.spotlightBox}>
                    <h3 className={`h3-16`}>Today's wines</h3>

                    <div className={styles.todaysWinesBoxRoble}>
                      <LandingPageCard
                        wine={spotlightWines[0]}
                        type={"RobleDemo"}
                      />
                      <LandingPageCard
                        wine={spotlightWines[1]}
                        type={"RobleDemo"}
                      />
                      <LandingPageCard
                        wine={spotlightWines[2]}
                        type={"RobleDemo"}
                      />
                    </div>
                  </div>
                ) : spotlightLoaded &&
                  !!spotlightWines &&
                  spotlightWines.length != 0 ? (
                  <div className={styles.spotlightBox}>
                    <h3 className={`h3-16`}>Today's wines</h3>

                    <div className={styles.todaysWinesBox}>
                      <LandingPageCard wine={spotlightWines[0]} />
                      <LandingPageCard wine={spotlightWines[1]} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className={styles.pairingsBox}>
                  <h3 className={`h3-16`}>Recent Pairings</h3>
                  {userSearchHistory && userSearchHistory.length > 0 ? (
                    userSearchHistory.map((item) => (
                      <div
                        key={item.recoId}
                        className={`${styles.phoneticResults}`}
                        onClick={() => onClickHistory(item)}
                      >
                        <img
                          className={`${styles.phoneticResultsImg}`}
                          src={item.itemType == 1 ? FoodIcon : WineIcon}
                          alt="Wine Icon"
                        />
                        <div>
                          {item.itemType == 1
                            ? item.searchValue
                            : item.varietal}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className={`p1-reg`}>Recent pairings will appear here</p>
                  )}
                </div>

                <div className={styles.pairingsBox}>
                  <h3 className={`h3-16`}>Saved Pairings</h3>
                  {!!userSavedPairings ? (
                    <div className={`${styles.savedCardBox}`}>
                      {userSavedPairings.slice(0, 2).map((item, idx) => (
                        <>
                          {!!item?.details ? (
                            <SavedPairingCard key={item.id} save={item} />
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                      {userSavedPairings.length == 1 ? (
                        <SavedPairingCard ghost />
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <p className={`p1-reg`}>Saved pairings will appear here</p>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </LayoutContents>
      </AnimationWrapper>
    </Layout>
  );
}
