import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../styles/global.scss";
import "../../styles/util.scss";
import "../../styles/animation.scss";
import "react-phone-number-input/style.css";
import styles from "./wine-varietal-card.module.scss";
import WineTypePill from "../../components/TargetComponents/WineTypePill/WineTypePill";
import { get, postEvent } from "../../lib/fetch";
import useSWR from "swr";
import * as constants from "../../constants/constants";
import FlavorProfileCard2 from "../../components/TargetComponents/FlavorProfileCard/FlavorProfileCard2";
import ActionsRow from "../../components/TargetComponents/ActionsRow/ActionsRow";
import PlacerLogo from "../../../assets/images/RanchoRobleDemo/placer-logo.png";
import RobleLogo from "../../../assets/images/RanchoRobleDemo/roble-logo.png";
import Feedback from "../../components/TargetComponents/PairingFeedback/Feedback";

export default function WineVarietalCard({
  pairingResult,
  wineDetails,
  foodName,
}) {
  const ref = useRef(null);

  // ====vvv

  const [wineDetailsLoaded, setWineDetailsLoaded] = useState(false);

  const [varietalName, setVarietalName] = useState(undefined);

  useEffect(() => {
    if (wineDetails) {
      if (wineDetails?.wineName == "Survivor Old Vine Zinfandel") {
        setVarietalName("Old Vine Zinfandel");
      } else if (wineDetails?.id == 1799) {
        setVarietalName("Rose Barb");
      } else if (wineDetails?.id == 1801) {
        setVarietalName("Rancho Barb");
      } else if (wineDetails?.id == 1798) {
        setVarietalName("Rancho Chard");
      } else {
        setVarietalName(wineDetails?.specifications?.varietal);
      }

      setWineDetailsLoaded(true);
    }
  }, [wineDetails]);
  // ====^^^

  const history = useHistory();

  const [profile, setProfile] = useState(undefined);

  const { data: varietalProfiles } = useSWR(
    `${constants.WINERY_API}/api/v1/public/varietal/getAllVarietalProfiles`,
    (u) => get(u).then((res) => res.json())
  );

  useEffect(() => {
    if (varietalProfiles && varietalName) {
      setProfile(varietalProfiles[varietalName.trimEnd()]);
    }
  }, [varietalProfiles, varietalName]);

  const clickSeeMore = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  if (!profile || !wineDetailsLoaded) return <></>;

  return (
    <div className={`w100`}>
      <div className={`mc ${styles.pairingImgBox} flex-row`}>
        <div className={`${styles.pairingImg}`}>
          <div className={`${styles.logosBox}`}>
            <img
              className={styles.placerLogo}
              src={PlacerLogo}
              alt={"Placer Wine Trail Logo"}
            />
            <img
              className={styles.robleLogo}
              src={RobleLogo}
              alt={"Rancho Roble Vineyards Logo"}
            />
          </div>
          <img
            className={styles.varietalImg}
            src={wineDetails?.wineImageSmall}
            alt="wine bottle"
          />
        </div>
      </div>
      <div className={styles.mainContainerSection}>
        <div className={`mc`}>
          <div className={`${styles.info}`}>
            <WineTypePill type={wineDetails?.wineType} />

            <h1 className={`h1-30`}>{wineDetails?.wineName}</h1>
            <div className={`${styles.fadeBox}`}>
              <p className={`p1-reg ${styles.fade}`}>
                {wineDetails?.wineDescription}
              </p>
              <p
                onClick={() => clickSeeMore()}
                className={`p1-reg ${styles.fadeSeeMore}`}
              >
                See More
              </p>
            </div>
          </div>
        </div>
        <div className={`mc`}>
          <button
            className={`${styles.buyBtn}`}
            onClick={() => {
              window.open(wineDetails?.wineUrl, "_blank");
            }}
          >
            <p className={`pb-lg`}>Buy Now</p>
            <svg
              className={`no-shrink`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
            >
              <path
                d="M10.6663 7.11161V10.7781C10.6663 11.1023 10.5376 11.4131 10.3084 11.6423C10.0792 11.8715 9.76829 12.0003 9.44415 12.0003H2.72218C2.39804 12.0003 2.08717 11.8715 1.85797 11.6423C1.62876 11.4131 1.5 11.1023 1.5 10.7781V4.05616C1.5 3.73202 1.62876 3.42115 1.85797 3.19195C2.08717 2.96275 2.39804 2.83398 2.72218 2.83398H6.38871"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.83325 1H12.4998V4.66653"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.7771 7.72198L12.4991 1"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className={`w100`}>
          <ActionsRow itemType="food_reco" foodName={foodName} pairingResult={pairingResult}/>
        </div>
        <div className={`mc`}>
          <div className={`${styles.varietalCard}`}>
            <FlavorProfileCard2 varietal={varietalName} noSubtitle />
          </div>
          <div className={`${styles.flavorProfBox}`}>
            <p className={`h3-16`} style={{ marginBottom: "8px" }}>
              Flavor Profile
            </p>
            <p ref={ref} className={`p1-reg`}>
              {wineDetails?.wineDescription}
            </p>
          </div>
        </div>
      </div>
      <div className={`mc`}>
        <Feedback />
      </div>
    </div>
  );
}
