import React from "react";
import VarietalCard from "./VarietalCard";
import WineVarietalCard from "./WineVarietalCard";

export default function WineOrVarietal({
  searchValue,
  foodName,
  varietalName,
  pairingResult,
}) {
  if (
    pairingResult?.wines?.filter((wine) => {
      return wine.id == 1799 || wine.id == 1798 || wine.id == 1801;
    }).length == 1
  ) {
    return (
      <WineVarietalCard
        searchValue={searchValue}
        foodName={foodName}
        wineDetails={pairingResult?.wines?.filter((wine) => {
          return wine.id == 1799 || wine.id == 1798 || wine.id == 1801;
        })[0]}
      />
    );
  } else {
    return (
      <VarietalCard
        searchValue={searchValue}
        foodName={foodName}
        varietalName={varietalName}
        pairingResult={pairingResult}
      />
    );
  }
}
